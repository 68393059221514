<template>
    <transition :name="animation">
        <div class="NewTask__Component__Editor_Inner" v-if="editor">
                <editor-content :editor="editor" />
                <div class="NewTask__Component__Editor__Actions">
                    <button class="editor_button" @click="editor.chain().focus().toggleBold().run()"
                        :class="{ 'is-active': editor.isActive('bold') }">
                        <b-icon
                            icon="format-bold"
                        ></b-icon>
                    </button>
                    <button class="editor_button"
                        @click="editor.chain().focus().toggleItalic().run()"
                        :class="{ 'is-active': editor.isActive('italic') }">
                        <b-icon
                            icon="format-italic"
                        ></b-icon>
                    </button>
                    <button class="editor_button"
                        @click="editor.chain().focus().toggleUnderline().run()"
                        :class="{ 'is-active': editor.isActive('underline') }">
                        <b-icon
                            icon="format-underline"
                        ></b-icon>
                    </button>
                    <button class="editor_button"
                        @click="editor.chain().focus().unsetAllMarks().run()">
                        <b-icon
                            icon="format-clear"
                        ></b-icon>
                    </button>
                </div>
            </div>
    </transition>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';

export default {
    name: 'NewTask',
    props: {
        content: String,
        animation: {
            type: String,
            default: 'fade',
        },
        placeholder: String,
    },
    components: {
        EditorContent,
    },
    computed: {
        hasContent() {
            return this.editor && !this.editor.isEmpty;
        },
    },
    mounted() {
        const self = this;
         this.editor = new Editor({
            content: '' || this.content,
             onUpdate({ editor }) {
                 self.$emit('onChange', editor.isEmpty);
            },
            extensions: [
                StarterKit,
                Underline,
                Placeholder.configure({
                    placeholder: this.placeholder || 'התחל להקליד על מנת לייצר משימה...',
                }),
            ],
             autofocus: true,
        });
    },
    data() {
        return {
            editor: null,
        };
    },
    methods: {
        close() {
            this.$emit('onClose');
        },
        save() {
            this.$emit('onSave', { html: this.editor.getHTML() });
        },
        getHTML() {
            return this.editor.getHTML();
        },
        isEmpty() {
            return this.editor.isEmpty;
        },
    },
    beforeDestroy() {
        this.editor.destroy();
    },
};
</script>

<style scoped lang="scss">
    ::v-deep .ProseMirror {
            padding: 10px;
            line-height: normal;
            font-size: 15px;

            &.ProseMirror-focused {
                outline: none;
            }

            p.is-editor-empty:first-child::before {
                content: attr(data-placeholder);
                float: right;
                font-size: 14px;
                color: #829CB9;
                line-height: 24px;
                pointer-events: none;
                height: 0;
            }
    }
    .NewTask__Component__Editor__Actions {
            margin: 10px 0;
            margin-bottom: 0;
            display: flex;

            .editor_button {
                border: none;
                background-color: transparent;
                border: 1px solid transparent;
                border-radius: 5px;
                padding: 0;
                cursor: pointer;
                transition: border .1s linear;

                &:hover {
                    border: 1px solid silver;
                }

                ::v-deep {
                    .mdi-24px::before {
                        font-size: 16px;
                    }
                }
                &.is-active{
                    background-color: #e8e8e8;
                    border: 1px solid silver;
                }
            }

        }
</style>
