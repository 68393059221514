var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: _vm.animation } }, [
    _vm.editor
      ? _c(
          "div",
          { staticClass: "NewTask__Component__Editor_Inner" },
          [
            _c("editor-content", { attrs: { editor: _vm.editor } }),
            _c("div", { staticClass: "NewTask__Component__Editor__Actions" }, [
              _c(
                "button",
                {
                  staticClass: "editor_button",
                  class: { "is-active": _vm.editor.isActive("bold") },
                  on: {
                    click: function($event) {
                      _vm.editor
                        .chain()
                        .focus()
                        .toggleBold()
                        .run()
                    }
                  }
                },
                [_c("b-icon", { attrs: { icon: "format-bold" } })],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "editor_button",
                  class: { "is-active": _vm.editor.isActive("italic") },
                  on: {
                    click: function($event) {
                      _vm.editor
                        .chain()
                        .focus()
                        .toggleItalic()
                        .run()
                    }
                  }
                },
                [_c("b-icon", { attrs: { icon: "format-italic" } })],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "editor_button",
                  class: { "is-active": _vm.editor.isActive("underline") },
                  on: {
                    click: function($event) {
                      _vm.editor
                        .chain()
                        .focus()
                        .toggleUnderline()
                        .run()
                    }
                  }
                },
                [_c("b-icon", { attrs: { icon: "format-underline" } })],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "editor_button",
                  on: {
                    click: function($event) {
                      _vm.editor
                        .chain()
                        .focus()
                        .unsetAllMarks()
                        .run()
                    }
                  }
                },
                [_c("b-icon", { attrs: { icon: "format-clear" } })],
                1
              )
            ])
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }